const Web3 = require('web3');
// const Web3 = require('../public/js/web3.min.js');
// const _jquery = require('jquery');
const OAuth2AuthCodePKCE = require('../public/js/index.umd.js');
const jquery = require('../public/js/jquery-3.7.1.min.js');

if (typeof window !== 'undefined') {
    window.$ = window.jQuery = jquery;
}
// after jquery attachment to window above
const bootstrap = require('../public/js/bootstrap/5.3.2/js/bootstrap.bundle.min.js');

if (typeof window !== 'undefined') {
    window.bootstrap = bootstrap;
    window.Web3 = Web3;
    window.OAuth2AuthCodePKCE = OAuth2AuthCodePKCE;
}
